import { _js } from '@ifixit/localize';
import { Utils } from 'Shared/utils';

/**
 * A global loading indicator that can be used for most situations.
 * See /Dev/Style/loading_indicator for examples.
 *
 * Public methods:
 *   - LoadingIndicator.withPromise(promise, options)
 *   - LoadingIndicator.withEventHandler(eventHandler, options)
 */
export default (window.LoadingIndicator = (function () {
   // Constants

   // Elements that will be disabled when the loading indicator is visible.
   // These will be selected as a parent of event.target, then children of
   // that element.
   let INPUTS = 'fieldset, .button-group, button, .button, input, .ifixit-control';

   let containerTemplate = window.hbsTemplates('LOADING_INDICATOR_HBS');

   let messageTemplate = window.hbsTemplates('LOADING_INDICATOR_MESSAGE_HBS');

   // Public functions

   /**
    * Example usage:
    * button.addEvent(LoadingIndicator.withEventHandler(eventHandler, options))
    *
    * Displays a loading indicator until the promise returned from
    * `eventHandler` resolves or rejects. Disables any nearby input controls
    * and prevents the event handler from running again until the promise
    * resolves. It's intended to be similar to button.addEvent(eventHandler).
    *
    * `eventHandler`: This will be passed the same arguments as a normal event
    *  handler, but must return a Promise. For example:
    *  `function() { return new Request.API_2_0(...).send(); }`
    *
    * `options`:
    *    - pendingMessage
    *    - successMessage
    *    - failureMessage: Translated messages to display for each state.
    *
    *    - elementsToDisable: Leave undefined to disable buttons that are
    *      parents or children of `event.target`. Set to `null` to not disable
    *      anything. Set to an array of Elements to disable those elements.
    *
    *    - elementTypesToDisable: If elementsToDisable is not set, set this
    *      to a css selector that selects nearby elements to disable.
    *
    *    - disableEventHandler: Prevents the event handler from being called
    *      again if the same event handler is already waiting on a promise.
    *      Defaults to true.
    */
   let LoadingIndicatorWithEventHandler = function (eventHandler, options) {
      // Set defaults. Doesn't set the `elementsToDisable` default, since it
      // can vary between events.
      options = Object.append(
         {
            disableEventHandler: true,
         },
         options || {}
      );

      let eventHandlerWaiting = false;

      // Return a new event handler function that proxies `eventHandler`.

      return function (event) {
         // Don't call their event handler at all if a previous event from this
         // event handler is still "loading."
         if (options.disableEventHandler && eventHandlerWaiting) {
            return;
         }

         let doneLoadingPromise = eventHandler.apply(this, Array.prototype.slice.call(arguments));

         // Allow the user to do nothing by returning a falsy value.
         if (!doneLoadingPromise) {
            return;
         }

         // Disable the nearby input elements, and re-enable them when the
         // `doneLoadingPromise` promise is resolved.

         let elementsToDisable = getElementsToDisable(options, event);

         eventHandlerWaiting = true;
         elementsToDisable.each(el => {
            el.set('disabled', 'disabled').addClass('disabled');
         });

         let stopWaiting = function () {
            eventHandlerWaiting = false;
            elementsToDisable.each(el => {
               el.removeClass('disabled').removeAttribute('disabled');
            });
         };

         // Re-enable the buttons whether or not the promise rejects.
         doneLoadingPromise.then(stopWaiting, stopWaiting);

         // Finally, show the loading indicator.
         LoadingIndicatorWithPromise(doneLoadingPromise, options);
      };
   };

   /**
    * Example usage:
    * LoadingIndicator.withPromise(promise, options)
    *
    * Displays a loading indicator until `promise` resolves or rejects.
    *
    * `options`:
    *    - pendingMessage
    *    - successMessage
    *    - failureMessage
    */
   // eslint-disable-next-line no-var
   var LoadingIndicatorWithPromise = function (promise, options) {
      // Set defaults.
      options = Object.append(
         {
            pendingMessage: _js('Processing'),
            successMessage: _js('Changes Saved'),
            failureMessage: _js('Error Saving Changes'),
            useErrorResponseAsFailureMessage: false,
            hideSuccessMessage: false,
            duration: 2300,
         },
         options || {}
      );

      // Ignore whatever the user's promise resolves with. Replace it with
      // the success or failure message. This is done so that this function
      // is easy to use with the promise returned by
      // `Request.API_2_0(...).send()`. Otherwise, the user would have to
      // manually override its resolve value every time.
      let loadingMessagePromise = promise.then(
         () => ({
            success: true,
            message: options.successMessage,
         }),
         err => {
            let message;
            if (options.useErrorResponseAsFailureMessage && err) {
               if (typeof err === 'string') {
                  message = err;
               } else if (err.message) {
                  message = err.message;
               }
            }
            message = message || options.failureMessage;

            throw {
               failure: true,
               message: message,
            };
         }
      );

      // Add it to the list of things that are currently loading. If the length
      // of this list goes from 0 to 1, the loading indicator will show up.
      addLoadingMessagePromise(loadingMessagePromise, options);
   };

   // Private functions

   // addLoadingMessagePromise adds a promise to the list of things that are
   // currently loading, and displays the loading indicator once this list gets
   // one promise and hides it once all promises are resolved.
   let loadingMessagePromises = [];
   function addLoadingMessagePromise(promise, options) {
      if (loadingMessagePromises.length > 0) {
         // If the loading indicator is already visible, just have it wait on
         // this promise too.
         loadingMessagePromises.push(promise);
      } else {
         // If it's the first one, show the loading indicator until it's
         // resovled.
         loadingMessagePromises.push(promise);

         // Ensure that the loading indicator shows for at least 0.4s,
         // otherwise it won't even finish animating in before it disappears.
         // Also, wait on any promises added after this one started loading.
         let waitAll = Utils.timeoutPromise(400).then(() => waitAllLoadingMessagePromises());

         showLoadingIndicatorUntil(waitAll, options);

         // Clear the list once the loading indicator disappears, so that the
         // next loadingMessagePromise will make the loading indicator
         // reappear.
         waitAll.then(
            () => {
               loadingMessagePromises = [];
            },
            () => {
               loadingMessagePromises = [];
            }
         );
      }
   }
   // Recursively waits for any loadingMessagePromises added after we started
   // waiting for them.
   function waitAllLoadingMessagePromises() {
      let oldLength = loadingMessagePromises.length;

      return Promise.all(loadingMessagePromises).then(data => {
         // eslint-disable-next-line unicorn/prefer-ternary
         if (loadingMessagePromises.length == oldLength) {
            // No new promises added while we were waiting.
            return data[0]; // Arbitrarily show one message.
         } else {
            return waitAllLoadingMessagePromises();
         }
      });
   }

   // Animates the loading indicator in and out. Shows a pending message
   // until loadingMessagePromise resolves.
   function showLoadingIndicatorUntil(loadingMessagePromise, options) {
      // If a finished loading indicator has started to animating away, make it
      // animate away faster.
      $$('.loading-indicator.leaving').addClass('leaving-now');

      // Show the pending message.
      let indicatorContainer = containerTemplate({
         pending: true,
         message: options.pendingMessage,
      });
      let indicatorElement = indicatorContainer.getElement('.loading-indicator');
      document.body.grab(indicatorContainer);
      loadingMessagePromise
         .then(
            data => {
               if (options.hideSuccessMessage) {
                  indicatorElement.removeClass('pending').addClass('leaving-now');
               } else {
                  indicatorElement.removeClass('pending').addClass('success');
                  renderMessage(indicatorElement, data);
               }
            },
            error => {
               indicatorElement.removeClass('pending').addClass('failure');
               renderMessage(indicatorElement, error);
            }
         )
         .then(() =>
            // Leave time for the user to read the message and for it to animate
            // away.
            Utils.timeoutPromise(options.duration)
         )
         .then(() => {
            // In case the css which controls the animation of the alert changes,
            // this will be backup to destroy the element
            let timeout = setTimeout(() => {
               indicatorElement.destroy();
            }, 2000);

            // Mootools doesn't support css animation events so use vanilla event listener
            document.querySelector('.loading-indicator').addEventListener(
               'animationend',
               () => {
                  indicatorContainer.destroy();
                  clearTimeout(timeout);
               },
               { once: true }
            );

            indicatorElement.addClass('leaving');
         });
   }

   // Changes the message in the loading indicator with an animation.
   function renderMessage(indicatorElement, data) {
      // Remove the old slidein animation.
      indicatorElement.removeClass('entering');

      // We can't animate from width:auto to width:auto, so we have to measure
      // the width with the old and new messages.
      indicatorElement.setStyle('width', 'auto');
      let initialWidth = indicatorElement.getSize().x;

      // Fade out the old message.
      indicatorElement.getChildren().addClass('leaving');

      // Render the new message.
      let messageContainer = messageTemplate(data);
      indicatorElement.grab(messageContainer);

      // Get the width of the indicator with the new message.
      let afterWidth = indicatorElement.getSize().x;

      // Animate in the new message.
      messageContainer.addClass('entering');

      // Animate the width.
      indicatorElement.setStyle('width', initialWidth);
      indicatorElement.offsetWidth; // trigger a reflow
      indicatorElement.setStyle('width', afterWidth);
   }

   // Heuristic for determining which input elements near event.target should
   // be disabled.
   function getElementsToDisable(options, event) {
      let inputElements = new Elements();

      if (options && options.elementsToDisable !== undefined) {
         // If the user manually specified elements, return those.
         inputElements = new Elements(options.elementsToDisable || []);
      } else if (event && event.target) {
         let inputTypes = options.inputTypesToDisable || INPUTS;
         // Otherwise, select the first input element that is a parent of
         // event.target, then select any input elements that are children of
         // that parent element.
         let rootElement = event.target.getParent(inputTypes) || event.target;
         inputElements = new Elements([rootElement]);
         inputElements.append(rootElement.getElements(inputTypes));
      }

      // Filter out any elements that are already disabled.
      inputElements = inputElements.filter(':not([disabled]):not(.disabled)');

      return inputElements;
   }

   // Expose public functions.
   return {
      withPromise: LoadingIndicatorWithPromise,
      withEventHandler: LoadingIndicatorWithEventHandler,
   };
})());
