import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reviewsReducer from 'Guide/ProductReviews/reducer';
import {
   TEAM_MEMBERS_STATE_ROOT,
   teamMembersReducer,
} from 'Guide/Courses/store/team-members.state';
import {
   INDIVIDUAL_DETAILS_STATE_ROOT,
   individualDetailsReducer,
} from 'Guide/Courses/store/individual-details.state';
import { COMPLETIONS_STATE_ROOT, completionsReducer } from 'Guide/Courses/store/completions.state';
import headerReducer from 'Shared/Header/reducer';
import popupReducer from 'Shared/PopupModal/reducer';
import { modalIsOpenSlice } from 'Shared/Header/shared-state';
import { hideSubBoxSlice } from 'Shared/newsletter_shared_state';
import { selectedWikiDeviceSlice, savingState, inputFormValue } from 'Guide/wiki_search_state';

/* Define the Reducers that will always be present in the application.
 * We need at least one reducer initially, so 'global' will be an empty reducer
 * that we can potentially use in the future if need be.
 */
const staticReducers = {
   reviewsState: reviewsReducer,
   [TEAM_MEMBERS_STATE_ROOT]: teamMembersReducer,
   [INDIVIDUAL_DETAILS_STATE_ROOT]: individualDetailsReducer,
   [COMPLETIONS_STATE_ROOT]: completionsReducer,
   headerState: headerReducer,
   popupState: popupReducer,
   [modalIsOpenSlice.name]: modalIsOpenSlice.reducer,
   [hideSubBoxSlice.name]: hideSubBoxSlice.reducer,
   [selectedWikiDeviceSlice.name]: selectedWikiDeviceSlice.reducer,
   [savingState.name]: savingState.reducer,
   [inputFormValue.name]: inputFormValue.reducer,
};

export const store = configureStore({
   // @ts-expect-error TS(2322) FIXME: Type '{ [x: string]: ((state: { curView: string; s... Remove this comment to see the full error message
   reducer: staticReducers,
   middleware: getDefaultMiddleware({
      // Redux doesn't work dependably if we mutate state objects that are
      // passed to the store. We've done this in the past here and there,
      // so we've exempted all of them for now. Don't add new entries here,
      // instead don't mutate objects that you save in the store.
      immutableCheck: {
         ignoredPaths: [
            'reviewsState',
            TEAM_MEMBERS_STATE_ROOT,
            INDIVIDUAL_DETAILS_STATE_ROOT,
            COMPLETIONS_STATE_ROOT,
            'headerState',
            'popupState',
            hideSubBoxSlice.name,
         ],
      },
      // Redux sometimes(?) serializes stored data and expects to get
      // equivalent values back when de-serializing.
      // We've sometimes stored unserializable values in the store,
      // so we've exempted all of the slices for now. Don't add new entries
      // here, instead only store state that can be round-tripped to JSON and
      // back.
      serializableCheck: {
         ignoredPaths: [
            'reviewsState',
            TEAM_MEMBERS_STATE_ROOT,
            INDIVIDUAL_DETAILS_STATE_ROOT,
            COMPLETIONS_STATE_ROOT,
            'headerState',
            'popupState',
            hideSubBoxSlice.name,
         ],
         ignoredActionPaths: ['notifications', 'popup'],
      },
   }),
});

export function onSelectedWikiDeviceChange(listener: (e: null | string) => void) {
   return store.subscribe(() => {
      listener(selectedWikiDeviceSlice.selector(store.getState()));
   });
}

export function onSavingStateChange(listener: (e: boolean) => void) {
   return store.subscribe(() => {
      listener(savingState.selector(store.getState()));
   });
}

export function onHideSubBoxChange(listener: (state: boolean) => void) {
   return store.subscribe(() => {
      listener(hideSubBoxSlice.selector(store.getState()));
   });
}

export const setModalIsOpen = modalIsOpenSlice.setter(store);
export const setHideSubBox = hideSubBoxSlice.setter(store);
export const getHideSubBox = () => hideSubBoxSlice.selector(store.getState());
export const setSelectedWikiDevice = selectedWikiDeviceSlice.setter(store);
export const getSelectedWikiDevice = () => selectedWikiDeviceSlice.selector(store.getState());
export const setSavingState = savingState.setter(store);
export const getSavingState = () => savingState.selector(store.getState());
export const setInputFormValue = inputFormValue.setter(store);
export const getInputFormValue = () => inputFormValue.selector(store.getState());

export type RootState = ReturnType<typeof store.getState>;
